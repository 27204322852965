import React, { useEffect, useState } from 'react';
import { AppBar, IconButton, Toolbar, Typography, Box, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { MobileView } from 'react-device-detect';
import Search from '../Search';

const Header = ({ handleDrawerToggle }) => {
  const [showSearch, setShowSearch] = useState(true);

  useEffect(() => {
    const currentPath = window.location.pathname;

    const segments = currentPath.split('/');
    if (segments.length - 1 === 2) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  }, [window.location.pathname]);
  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={0}
      sx={{
        width: '100vw',
        pt: 2,
        height: 80,
      }}
    >
      <Toolbar>
        <MobileView>
          <IconButton color="primary" aria-label="menu" orientation="horizontal" sx={{ mr: 2, mb: 2 }} onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </MobileView>
        <Typography
          variant="h3"
          noWrap
          component="a"
          href="/"
          sx={{
            ml: 4,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          LOGO
        </Typography>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          {showSearch && <Search />}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
