export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const SHOW_DIS_ALL = 'SHOW_DIS_ALL';
export const SHOW_DIS = 'SHOW_DIS';
export const SHOW_DIS_FAIL = 'SHOW_DIS_FAIL';
export const ADD_DIS = 'ADD_DIS';
export const UPDATE_DIS = 'UPDATE_DIS';
export const REMOVE_DIS = 'REMOVE_DIS';

export const SET_MENU = 'SET_MENU';
export const MENU_OPEN = 'MENU_OPEN';

export const SHOW_STATION_ALL = 'SHOW_STATION_ALL';
export const SHOW_STATION_DETAILS = 'SHOW_STATION_DETAILS';
export const ADD_STATION = 'ADD_STATION';
export const REMOVE_STATION = 'REMOVE_STATION';

export const ADD_GUN = 'ADD_GUN';
export const UPDATE_GUN = 'UPDATE_GUN';
export const ADD_LEGALIZATION = 'ADD_LEGALIZATION';
export const ADD_VRS_LEGALIZATION = 'ADD_VRS_LEGALIZATION';

export const SHOW_MODAL_FAIL = 'SHOW_MODAL_FAIL';
export const SHOW_MODAL = 'SHOW_MODAL';

export const SHOW_MODAL_FAB_FAIL = 'SHOW_MODAL_FAB_FAIL';
export const SHOW_MODAL_FAB = 'SHOW_MODAL_FAB';
export const REMOVE_GUN = 'REMOVE_GUN';

export const SHOW_LEG_FAIL = 'SHOW_LEG_FAIL';
export const SHOW_MODAL_LEG = 'SHOW_MODAL_LEG';
export const SHOW_LEG = 'SHOW_LEG';

export const SHOW_INVOICE_STATION = 'SHOW_INVOICE_STATION';
export const ADD_INVOICE = 'ADD_INVOICE';
export const REMOVE_INVOICE = 'REMOVE_INVOICE';

export const SHOW_PROTOCOL_STATION = 'SHOW_PROTOCOL_STATION';
export const ADD_PROTOCOL = 'ADD_PROTOCOL';
export const REMOVE_PROTOCOL = 'REMOVE_PROTOCOL';

export const SHOW_GALLERY_STATION = 'SHOW_GALLERY_STATION';
export const ADD_GALLERY = 'ADD_GALLERY';
export const REMOVE_GALLERY = 'REMOVE_GALLERY';

export const SHOW_TANK_STATION = 'SHOW_TANK_STATION';
export const SHOW_TANK = 'SHOW_TANK';
export const SHOW_TANK_FAIL = 'SHOW_TANK_FAIL';
export const ADD_TANK = 'ADD_TANK';
export const REMOVE_TANK = 'REMOVE_TANK';

export const ADD_CAVE = 'ADD_CAVE';
export const REMOVE_CAVE = 'REMOVE_CAVE';
export const UPDATE_CAVE = 'UPDATE_CAVE';

export const SHOW_TANK_TEST_ALL = 'SHOW_TANK_TEST_ALL';
export const ADD_TANK_TEST = 'ADD_TANK_TEST';
export const SHOW_MODAL_TEST = 'SHOW_MODAL_TEST';
export const SHOW_MODAL_TEST_FAIL = 'SHOW_MODAL_TEST_FAIL';

export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';

export const SHOW_GUN_ALL = 'SHOW_GUN_ALL';
export const SHOW_DASHBOARD = 'SHOW_DASHBOARD';
export const EDIT_MODAL = 'EDIT_MODAL';
export const UPDATE_STATION = 'UPDATE_STATION';
