import { Navigate, useRoutes } from 'react-router-dom';
import { lazy } from 'react';
import Loadable from './components/Loadable';

import Login from './views/Login';
import MainTemplate from './templates/MainTemplate';

const Home = Loadable(lazy(() => import('./views/Home')));
const Stations = Loadable(lazy(() => import('./views/Stations')));
const Distributions = Loadable(lazy(() => import('./views/Distributions')));
const Guns = Loadable(lazy(() => import('./views/Guns')));

const StationDetails = Loadable(lazy(() => import('./views/StationDetails')));

const Page404 = Loadable(lazy(() => import('./views/Page404')));

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <MainTemplate />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/stations',
          children: [
            { index: true, element: <Stations /> },
            { path: ':id', element: <StationDetails /> },
          ],
        },
        {
          path: '/distibutions',
          children: [{ index: true, element: <Distributions /> }],
        },
        {
          path: '/guns',
          children: [{ index: true, element: <Guns /> }],
        },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '*',
      element: <Page404 />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
  ]);

  return routes;
}
