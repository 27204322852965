import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const register = (name, email, password) =>
  axios.post(`${API_URL}register`, {
    name,
    email,
    password,
  });

const login = (email, password) =>
  axios
    .post(`${API_URL}login`, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data.token));
      }
      return response.data.token;
    })
    .catch((err) => Promise.reject(err.response.data.message));

const logout = () => {
  localStorage.removeItem('user');
};

export default {
  register,
  login,
  logout,
};
