import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { IconDeviceMobileOff } from '@tabler/icons-react';

const MobileTemplate = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        m: 0,
        p: 7,
        alignItems: 'center',
        with: '100vh',
        height: '100vh',
        bgcolor: theme.palette.primary.main,
        color: theme.palette.text.primary,
      }}
    >
      <IconDeviceMobileOff size="60" />
      <Typography mt={6} align="center" variant="h1">
        Aplikacja nie dostępna na urządzenia Mobilne
      </Typography>
      <Typography mt={2} align="center" variant="h2">
        Proszę użyć większęgo sprzętu
      </Typography>
    </Box>
  );
};
export default MobileTemplate;
