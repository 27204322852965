import { IconDropCircle, IconGasStation, IconArrowBearLeft } from '@tabler/icons-react';

const icons = {
  IconDropCircle,
  IconGasStation,
  IconArrowBearLeft,
};

const data = {
  id: '2',
  type: 'group',
  children: [
    {
      id: 'stations',
      title: 'Stacje',
      type: 'item',
      url: '/stations',
      icon: icons.IconDropCircle,
    },
    /*   {
      id: 'dis',
      title: 'Dystrybutory',
      type: 'item',
      url: '/distibutions',
      icon: icons.IconGasStation,
    },
    {
      id: 'guns',
      title: 'Pistolety',
      type: 'item',
      url: '/guns',
      icon: icons.IconArrowBearLeft,
    }, */
  ],
};

export default data;
