/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
import { SHOW_TANK_TEST_ALL, ADD_TANK_TEST } from '../actions/typ';

const initialState = { data: { badania: [] } };

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_TANK_TEST_ALL:
      return {
        ...state,
        data: payload,
      };

    case ADD_TANK_TEST:
      const noweBadanie = payload.data;

      const istniejeRodzajBadania = state.data.badania.some((badanie) => badanie.rodzaj_badania_id === noweBadanie.rodzaj_badania_id);

      if (istniejeRodzajBadania) {
        const zaktualizowaneBadania = state.data.badania.map((badanie) => {
          if (badanie.rodzaj_badania_id === noweBadanie.rodzaj_badania_id) {
            return noweBadanie;
          }
          return badanie;
        });

        return {
          ...state,
          data: {
            ...state.data,
            badania: zaktualizowaneBadania,
          },
        };
      }
      const noweBadania = [...state.data.badania, noweBadanie];

      return {
        ...state,
        data: {
          ...state.data,
          badania: noweBadania,
        },
      };

    default:
      return state;
  }
}
