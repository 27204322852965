import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import App from './App';
import './styles.css';

const container = document.getElementById('root');
const root = createRoot(container);
container.id = 'myAppContainer';

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <div id="myAppContainer">
        <App />
      </div>
    </BrowserRouter>
  </Provider>,
);
