import { SHOW_INVOICE_STATION, REMOVE_INVOICE, ADD_INVOICE } from '../actions/typ';

const initialState = {
  data: { faktury: [] },
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_INVOICE_STATION:
      return {
        data: payload.data,
      };
    case ADD_INVOICE:
      return {
        ...state,
        data: { ...state.data, faktury: [payload.data, ...state.data.faktury] },
      };
    case REMOVE_INVOICE:
      // eslint-disable-next-line no-case-declarations
      const updatedFaktury = state.data.faktury.filter((faktura) => faktura.id !== payload.id);
      return {
        ...state,
        data: { ...state.data, faktury: updatedFaktury },
      };
    default:
      return state;
  }
}
