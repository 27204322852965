/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BrowserView } from 'react-device-detect';

import {
  Box,
  Button,
  Container,
  Grid,
  FormHelperText,
  Typography,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormControl,
  Alert,
  AlertTitle,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { login } from '../actions/auth';

const Login = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { show } = useSelector((state) => state.message);

  useEffect(() => {
    if (isSubmitted && isLoggedIn) {
      navigate('/');
      window.location.reload();
    }
  }, [isSubmitted, isLoggedIn]);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        maxHeight: '100vh',
        width: '100%',
      }}
      spacing={0}
    >
      <Grid item md={4} xs={0}>
        <Box
          sx={{
            p: 2,
            height: '100vh',
            [theme.breakpoints.down('md')]: {
              height: '100%',
            },
            width: '100%',
          }}
        >
          <Box
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderRadius: 3,
              bgcolor: theme.palette.primary.main,
              height: '100%',
              width: '100%',
              [theme.breakpoints.down('md')]: {
                borderRadius: 0,
                bgcolor: theme.palette.primary.light,
              },
            }}
          >
            <BrowserView>
              <Box sx={{ pt: 2 }}>
                <Typography align="left" variant="h4">
                  Nazwa firmy
                </Typography>
              </Box>
            </BrowserView>
            <Box
              sx={{
                width: '100%',
                mt: { xs: 3, md: -20 },
              }}
            >
              <Typography
                align="center"
                variant="h1"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                Nazwa systemu
              </Typography>
              <Typography
                sx={{
                  pt: 2,
                  [theme.breakpoints.down('md')]: {
                    color: theme.palette.grey[500],
                  },
                }}
                align="center"
                variant="h4"
              >
                Nazwa firmy
              </Typography>
            </Box>
            <BrowserView>
              <Box
                sx={{
                  p: 4,
                  justifyContent: 'space-evenly',
                  alignItems: 'flex-end',
                  alignSelf: 'flex-end',
                }}
              >
                <Typography align="center" level="body1">
                  Motto
                </Typography>
              </Box>
            </BrowserView>
          </Box>
        </Box>
      </Grid>

      <Grid item md={8} xs={0}>
        <Container maxWidth="sm" sx={{ mt: { xs: 1, sm: 0 }, p: 2 }}>
          <Box
            sx={{
              p: { xs: 2, sm: 2, xl: 5 },
              [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                height: '100vh',
              },
            }}
          >
            <Typography align="center" color="textPrimary" variant="h1">
              Logowanie
            </Typography>
            <Formik
              initialValues={{
                email: 'monkeymr014@gmail.com',
                password: 'frse12345',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Proszę podać email').max(255).required('Proszę podać email'),
                password: Yup.string().max(255).required('Proszę podać hasło'),
              })}
              onSubmit={({ email, password }) => {
                setIsSubmitted(true);
                dispatch(login(email, password))
                  .then(() => {
                    if (isLoggedIn) {
                      navigate('/');
                    }
                  })
                  .catch(() => {
                    setIsSubmitted(false);
                  });
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  {show && (
                    <Alert severity="error" variant="outlined">
                      <AlertTitle>Nieprawidłowe dane logowania</AlertTitle>
                    </Alert>
                  )}
                  <FormControl required variant="outlined" fullWidth error={Boolean(touched.email && errors.email)} sx={{ mt: 2, width: '100%' }}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput
                      id="email"
                      type="email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Email"
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl required error={Boolean(touched.password && errors.password)} sx={{ mt: 2, width: '100%' }} variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      id="password"
                      value={values.password}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )}
                      label="Password"
                    />
                    {touched.password && errors.password && (
                      <FormHelperText error id="standard-weight-helper-text-password-login">
                        {errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Box sx={{ mt: 2 }}>
                    <Button fullWidth size="large" type="submit" variant="contained" color="primary">
                      Login
                    </Button>
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
