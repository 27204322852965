/* eslint-disable camelcase */
import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  SHOW_STATION_ALL,
  SHOW_STATION_DETAILS,
  ADD_STATION,
  REMOVE_STATION,
  UPDATE_SEARCH_TERM,
  UPDATE_STATION,
} from './typ.js';

import DataService from '../services/data.service';

export const showStationAll = () => (dispatch) =>
  DataService.getStationAll().then((res) => {
    dispatch({
      type: SHOW_STATION_ALL,
      payload: res.data,
    });

    return Promise.resolve();
  });

export const showStationDetails = (id) => (dispatch) =>
  DataService.getStationDetails(id).then((res) => {
    dispatch({
      type: SHOW_STATION_DETAILS,
      payload: res.data,
    });

    return Promise.resolve();
  });

export const addStation = (nazwa_stacji, miejscowosc, kod_pocztowy, ulica, email) => (dispatch) =>
  DataService.addStation(nazwa_stacji, miejscowosc, kod_pocztowy, ulica, email).then((res) => {
    const { data } = res.data;
    const { message } = res.data;
    const { status } = res.data;

    dispatch({
      type: ADD_STATION,
      payload: { data },
    });
    dispatch({
      type: SET_MESSAGE,
      payload: { message, status },
    });
    setTimeout(() => dispatch({ type: CLEAR_MESSAGE }), 3000);
  });

export const updateStation = (nazwa_stacji, miejscowosc, kod_pocztowy, ulica, email, id) => (dispatch) =>
  DataService.updateStation(nazwa_stacji, miejscowosc, kod_pocztowy, ulica, email, id).then((res) => {
    const { data } = res.data;
    const { message } = res.data;
    const { status } = res.data;

    dispatch({
      type: UPDATE_STATION,
      payload: { data },
    });
    dispatch({
      type: SET_MESSAGE,
      payload: { message, status },
    });
    setTimeout(() => dispatch({ type: CLEAR_MESSAGE }), 3000);
  });
export const deleteStation = (id) => (dispatch) =>
  DataService.deleteStation(id).then((res) => {
    const { status } = res.data;
    const { message } = res.data;
    dispatch({
      type: REMOVE_STATION,
      payload: { id },
    });
    dispatch({
      type: SET_MESSAGE,
      payload: { message, status },
    });
    setTimeout(() => dispatch({ type: CLEAR_MESSAGE }), 3000);
    return Promise.resolve();
  });

export const updateSearchTerm = (term) => ({
  type: UPDATE_SEARCH_TERM,
  payload: term,
});
