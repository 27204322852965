/* eslint-disable no-case-declarations */
import {
  ADD_LEGALIZATION,
  ADD_GUN,
  UPDATE_GUN,
  REMOVE_GUN,
  UPDATE_DIS,
  ADD_CAVE,
  ADD_DIS,
  ADD_TANK,
  REMOVE_DIS,
  SHOW_STATION_DETAILS,
  ADD_VRS_LEGALIZATION,
  REMOVE_TANK,
  REMOVE_CAVE,
  UPDATE_CAVE,
} from '../actions/typ';

const initialState = {
  data: { dystrybutory: [{ pistolety: [] }] },
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_STATION_DETAILS:
      return {
        data: payload.data,
      };
    case ADD_DIS:
      return {
        ...state,
        data: { ...state.data, dystrybutory: [...state.data.dystrybutory, payload.data] },
      };
    case UPDATE_DIS:
      const updatedDystrybutory = state.data.dystrybutory.map((item) => {
        if (item.id === payload.data.id) {
          return { ...item, ...payload.data };
        }
        return item;
      });
      return {
        ...state,
        data: { ...state.data, dystrybutory: updatedDystrybutory },
      };
    case REMOVE_DIS:
      const delDystrybutory = state.data.dystrybutory.filter((item) => item.id !== payload.id);
      return {
        ...state,
        data: { ...state.data, dystrybutory: delDystrybutory },
      };
    case REMOVE_TANK:
      const delTank = state.data.zbiorniki.filter((item) => item.id !== payload.id);
      return {
        ...state,
        data: { ...state.data, zbiorniki: delTank },
      };
    case ADD_GUN:
      return {
        ...state,
        data: {
          ...state.data,
          dystrybutory: state.data.dystrybutory.map((dystrybutor) => {
            if (dystrybutor.id === payload.data.dystrybutory_id) {
              return {
                ...dystrybutor,
                pistolety: [...dystrybutor.pistolety, payload.data],
              };
            }
            return dystrybutor;
          }),
        },
      };
    case REMOVE_GUN:
      return {
        ...state,
        data: {
          ...state.data,
          dystrybutory: state.data.dystrybutory.map((dystrybutor) => {
            if (dystrybutor.id === payload.disId) {
              return {
                ...dystrybutor,
                pistolety: dystrybutor.pistolety.filter((pistolet) => pistolet.id !== payload.id),
              };
            }
            return dystrybutor;
          }),
        },
      };
    case UPDATE_GUN:
      return {
        ...state,
        data: {
          ...state.data,
          dystrybutory: state.data.dystrybutory.map((dystrybutor) => {
            if (dystrybutor.id === payload.data.dystrybutory_id) {
              return {
                ...dystrybutor,
                pistolety: dystrybutor.pistolety.map((pistolet) => {
                  if (pistolet.id === payload.data.id) {
                    return {
                      ...pistolet,
                      ...payload.data,
                    };
                  }
                  return pistolet;
                }),
              };
            }
            return dystrybutor;
          }),
        },
      };

    case ADD_TANK:
      return {
        ...state,
        data: { ...state.data, zbiorniki: [...state.data.zbiorniki, payload.data] },
      };

    case ADD_VRS_LEGALIZATION:
      return {
        ...state,
        data: {
          ...state.data,
          dystrybutory: state.data.dystrybutory.map((dystrybutor) => {
            if (dystrybutor.id === payload.data.dystrybutory_id) {
              return {
                ...dystrybutor,
                vrs: [payload.data, ...dystrybutor.vrs],
              };
            }
            return dystrybutor;
          }),
        },
      };
    case ADD_LEGALIZATION:
      return {
        ...state,
        data: {
          ...state.data,
          dystrybutory: state.data.dystrybutory.map((dystrybutor) => {
            const updatedPistolety = dystrybutor.pistolety.map((pistolet) => {
              if (pistolet.id === payload.data.pistolety_id) {
                const updatedLegalizacje = [payload.data, ...pistolet.legalizacje];
                return {
                  ...pistolet,
                  legalizacje: updatedLegalizacje,
                };
              }
              return pistolet;
            });
            if (updatedPistolety !== dystrybutor.pistolety) {
              return {
                ...dystrybutor,
                pistolety: updatedPistolety,
              };
            }
            return dystrybutor;
          }),
        },
      };
    case ADD_CAVE:
      return {
        ...state,
        data: {
          ...state.data,
          zbiorniki: state.data.zbiorniki.map((zbiornik) => {
            if (zbiornik.id === payload.data.zbiorniki_id) {
              return {
                ...zbiornik,
                komory: [...zbiornik.komory, payload.data],
              };
            }
            return zbiornik;
          }),
        },
      };
    case UPDATE_CAVE:
      return {
        ...state,
        data: {
          ...state.data,
          zbiorniki: state.data.zbiorniki.map((zbiornik) => {
            if (zbiornik.id === payload.data.zbiorniki_id) {
              return {
                ...zbiornik,
                komory: zbiornik.komory.map((komora) => {
                  if (komora.id === payload.data.id) {
                    return {
                      ...zbiornik,
                      ...payload.data,
                    };
                  }
                  return zbiornik;
                }),
              };
            }
            return zbiornik;
          }),
        },
      };
    case REMOVE_CAVE:
      return {
        ...state,
        data: {
          ...state.data,
          zbiorniki: state.data.zbiorniki.map((zbiornik) => {
            if (zbiornik.id === payload.disId) {
              return {
                ...zbiornik,
                komory: zbiornik.komory.filter((komora) => komora.id !== payload.id),
              };
            }
            return zbiornik;
          }),
        },
      };

    default:
      return state;
  }
}
