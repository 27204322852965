/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
import { SHOW_DASHBOARD, UPDATE_SEARCH_TERM } from '../actions/typ';

const initialState = {
  dataDashboard: [],
  filteredData: [],
};

export default function stationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_DASHBOARD:
      return {
        ...state,
        filteredData: payload,
        dataDashboard: payload,
      };
    case UPDATE_SEARCH_TERM:
      const filteredData = state.dataDashboard.filter((item) =>
        Object.values(item).some((value) => {
          if (value && typeof value === 'string') {
            return value.toLowerCase().includes(payload.toLowerCase());
          }
          return false;
        }));
      return {
        ...state,
        searchTerm: payload.term,
        filteredData, // Zaktualizowane pole filteredData
      };
    default:
      return state;
  }
}
