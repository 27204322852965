import { SHOW_PROTOCOL_STATION, REMOVE_PROTOCOL, ADD_PROTOCOL } from '../actions/typ';

const initialState = {
  data: { protokoly: [] },
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_PROTOCOL_STATION:
      return {
        data: payload.data,
      };

    case ADD_PROTOCOL:
      return {
        ...state,
        data: { ...state.data, protokoly: [payload.data, ...state.data.protokoly] },
      };
    case REMOVE_PROTOCOL:
      // eslint-disable-next-line no-case-declarations
      const updated = state.data.protokoly.filter((protokol) => protokol.id !== payload.id);
      return {
        ...state,
        data: { ...state.data, protokoly: updated },
      };
    default:
      return state;
  }
}
