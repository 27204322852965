import React, { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useDispatch, useSelector } from 'react-redux';
import { MENU_OPEN } from '../../../../actions/typ';

const NavItem = ({ item, level }) => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customizationReducer);
  let listItemProps = {
    // eslint-disable-next-line react/no-unstable-nested-components, react/jsx-props-no-spreading
    component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target="_self" />),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: '_self' };
  }
  const itemHandler = (id) => {
    dispatch({ type: MENU_OPEN, id });
  };

  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }
  }, []);

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: 6,
        height: 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );
  return (
    <ListItemButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...listItemProps}
      sx={{
        borderRadius: '10px',
        mb: 0.5,
        m: 1,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
      }}
      selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItemButton>
  );
};
export default NavItem;
