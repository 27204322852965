import {
  SHOW_MODAL,
  SHOW_MODAL_FAB,
  SHOW_MODAL_FAB_FAIL,
  SHOW_DIS,
  SHOW_DIS_FAIL,
  SHOW_MODAL_FAIL,
  SHOW_MODAL_LEG,
  SHOW_LEG,
  SHOW_LEG_FAIL,
  SHOW_TANK,
  SHOW_TANK_FAIL,
  SHOW_MODAL_TEST,
  SHOW_MODAL_TEST_FAIL,
  EDIT_MODAL,
} from '../actions/typ';

const initialState = {
  showModal: false,
  editModal: false,
  editData: {},
  showModalFab: false,
  showModalLeg: false,
  showModalTest: false,
  lbType: 'default',
  rbType: 'dis',
  gunId: null,
  gunType: '',
  gunName: '',
  gunZal: '',
  disData: '',
  tankData: '',
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case EDIT_MODAL:
      return {
        ...state,
        editModal: true,
        editData: payload.data,
      };
    case SHOW_MODAL_FAIL:
      return {
        ...state,
        showModal: false,
        editModal: false,
      };

    case SHOW_MODAL_TEST:
      return {
        ...state,
        showModalTest: true,
      };
    case SHOW_MODAL_TEST_FAIL:
      return {
        ...state,
        showModalTest: false,
      };

    case SHOW_MODAL_FAB:
      return {
        ...state,
        showModalFab: true,
      };
    case SHOW_MODAL_FAB_FAIL:
      return {
        ...state,
        showModalFab: false,
      };
    case SHOW_DIS:
      return {
        ...state,
        lbType: 'dis',
        disData: payload,
      };

    case SHOW_TANK:
      return {
        ...state,
        lbType: 'tank',
        rbType: 'tank',
        tankData: payload,
      };

    case SHOW_TANK_FAIL:
      return {
        ...state,
        rbType: 'dis',
        lbType: 'default',
        tankData: null,
      };
    case SHOW_DIS_FAIL:
      return {
        ...state,
        lbType: 'default',
        disData: null,
      };
    case SHOW_LEG:
      return {
        ...state,
        rbType: 'gun',
        gunId: payload.gunId,
        gunType: payload.gunType,
        gunName: payload.gunName,
        gunZal: payload.gunZal,
      };
    case SHOW_MODAL_LEG:
      return {
        ...state,
        showModalLeg: true,
      };

    case SHOW_LEG_FAIL:
      return {
        ...state,
        rbType: 'dis',
      };

    default:
      return state;
  }
}
