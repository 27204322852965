/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
import { SHOW_GUN_ALL, UPDATE_SEARCH_TERM, SHOW_DIS, SHOW_DIS_FAIL } from '../actions/typ';

const initialState = { showDis: false, data: [], searchTerm: '', filteredData: [] };

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_GUN_ALL:
      return {
        ...state,
        data: payload,
        filteredData: payload,
      };

    case UPDATE_SEARCH_TERM:
      const filteredData = state.data.filter((item) => {
        const values = Object.values(item);

        const nestedValues = Object.values(item.dystrybutory);
        const nestedStacjeValues = Object.values(item.dystrybutory.stacje);

        const allValues = [...values, ...nestedValues, ...nestedStacjeValues];

        return allValues.some((value) => value && typeof value === 'string' && value.toLowerCase().includes(payload.toLowerCase()));
      });

      return {
        ...state,
        searchTerm: payload,
        filteredData,
      };
    default:
      return state;
  }
}
