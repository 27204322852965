import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import modal from './modal';
import dis from './dis';
import station from './station';
import stationDetails from './stationDetails';
import invoice from './invoice';
import protocol from './protocol';
import gallery from './gallery';
import gun from './gun';
import tankTest from './tankTest';
import dashboard from './dashboard';

import customizationReducer from './customizationReducer';

export default combineReducers({
  modal,
  auth,
  gun,
  dis,
  message,
  station,
  stationDetails,
  invoice,
  protocol,
  gallery,
  customizationReducer,
  tankTest,
  dashboard,
});
