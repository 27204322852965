import { IconLogin, IconError404 } from '@tabler/icons-react';

// assets
const icons = {
  IconLogin,
  IconError404,
};
// constant

const item1 = {
  id: '',
  type: 'group',
  children: [
    {
      id: 'app',
      title: 'Wyolguj',
      type: 'item',
      url: '/login',
      icon: icons.IconLogin,
    },
  ],
};

export default item1;
