/* eslint-disable camelcase */

import axios from 'axios';
import authHeader from './auth-header';
import { logout } from '../actions/auth';

const handleUnauthorized = (error) => {
  if (error.response && error.response.status === 401) {
    console.log('dupa');
    localStorage.removeItem('user');
    window.location.reload();
  }
  throw error;
};

const API_URL = process.env.REACT_APP_API_URL;

const getStationAll = () => axios.get(`${API_URL}stacje`, { headers: authHeader() });

const getStationDetails = (id) => axios.get(`${API_URL}stacje/${id}`, { headers: authHeader() });

const addStation = (nazwa_stacji, miejscowosc, kod_pocztowy, ulica, email) =>
  axios.post(`${API_URL}stacje`, { nazwa_stacji, miejscowosc, kod_pocztowy, ulica, email }, { headers: authHeader() });

const updateStation = (nazwa_stacji, miejscowosc, kod_pocztowy, ulica, email, id) =>
  axios.put(`${API_URL}stacje/${id}`, { nazwa_stacji, miejscowosc, kod_pocztowy, ulica, email }, { headers: authHeader() });

const deleteStation = (id) => axios.delete(`${API_URL}stacje/${id}`, { headers: authHeader() });

const getDisAll = () => axios.get(`${API_URL}dystrybutory`, { headers: authHeader() });

const getGunAll = () => axios.get(`${API_URL}pistolety`, { headers: authHeader() });

const updateGun = (dystrybutory_id, produkty_id, nr_czujnika, wydajnosc, id) =>
  axios.put(`${API_URL}pistolety/${id}`, { dystrybutory_id, produkty_id, nr_czujnika, wydajnosc }, { headers: authHeader() });

const getTankTestsAll = (tankData) => axios.get(`${API_URL}zbiorniki/${tankData}`, { headers: authHeader() });

const addDistribution = (stacje_id, model, nr_seryjny, vrs_date) =>
  axios.post(`${API_URL}dystrybutory`, { stacje_id, model, nr_seryjny, vrs_date }, { headers: authHeader() });

const updateDis = (stacje_id, model, nr_seryjny, vrs_date, id) =>
  axios.put(`${API_URL}dystrybutory/${id}`, { stacje_id, model, nr_seryjny, vrs_date }, { headers: authHeader() });

const deleteDis = (id) => axios.delete(`${API_URL}dystrybutory/${id}`, { headers: authHeader() });

const deleteGun = (id) => axios.delete(`${API_URL}pistolety/${id}`, { headers: authHeader() });

const addGun = (dystrybutory_id, produkty_id, nr_czujnika, wydajnosc) =>
  axios.post(`${API_URL}pistolety`, { dystrybutory_id, produkty_id, nr_czujnika, wydajnosc }, { headers: authHeader() });

const addCave = (zbiorniki_id, nr_ewidencyjny, produkty_id) =>
  axios.post(`${API_URL}komory`, { zbiorniki_id, nr_ewidencyjny, produkty_id }, { headers: authHeader() });

const deleteCave = (id) => axios.delete(`${API_URL}komory/${id}`, { headers: authHeader() });

const updateCave = (zbiorniki_id, nr_ewidencyjny, produkty_id, id) =>
  axios.put(`${API_URL}komory/${id}`, { zbiorniki_id, nr_ewidencyjny, produkty_id }, { headers: authHeader() });

const addTank = (stacje_id, nr_fabryczny, nr_dozorowy) =>
  axios.post(`${API_URL}zbiorniki`, { stacje_id, nr_fabryczny, nr_dozorowy }, { headers: authHeader() });

const deleteTank = (id) => axios.delete(`${API_URL}zbiorniki/${id}`, { headers: authHeader() });

const addTankTest = (zbiorniki_id, data_badania, opis, rodzaj_badania_id) =>
  axios.post(`${API_URL}badania`, { zbiorniki_id, data_badania, opis, rodzaj_badania_id }, { headers: authHeader() });

const addLegalization = (pistolety_id, data_legalizacji, zalacznik) => {
  const formData = new FormData();
  formData.append('zalacznik', zalacznik);
  formData.append('data_legalizacji', data_legalizacji);
  formData.append('pistolety_id', pistolety_id);

  return axios.post(`${API_URL}legalizacje`, formData, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

const addVRSLegalization = (dystrybutory_id, vrs_date) => axios.post(`${API_URL}vrs`, { dystrybutory_id, vrs_date }, { headers: authHeader() });

const getInvoiceStation = (id) => axios.get(`${API_URL}faktury/${id}`, { headers: authHeader() });

const addInvoice = (zalacznik, stacje_id, opis, nr_faktury) => {
  const formData = new FormData();
  formData.append('zalacznik', zalacznik);
  formData.append('stacje_id', stacje_id);
  formData.append('opis', opis);
  formData.append('nr_faktury', nr_faktury);

  return axios.post(`${API_URL}faktury`, formData, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteInvoice = (id) => axios.delete(`${API_URL}faktury/${id}`, { headers: authHeader() });

const getProtocolStation = (id) => axios.get(`${API_URL}protokoly/${id}`, { headers: authHeader() });

const addProtocol = (zalacznik, stacje_id, opis, nr_protokolu) => {
  const formData = new FormData();
  formData.append('zalacznik', zalacznik);
  formData.append('stacje_id', stacje_id);
  formData.append('opis', opis);
  formData.append('nr_protokolu', nr_protokolu);

  return axios.post(`${API_URL}protokoly`, formData, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
};
const deleteProtocol = (id) => axios.delete(`${API_URL}protokoly/${id}`, { headers: authHeader() });

const getGalleryStation = (id) => axios.get(`${API_URL}zdjecia/${id}`, { headers: authHeader() });

const addGallery = (zalacznik, stacje_id, opis) => {
  const formData = new FormData();
  formData.append('zalacznik', zalacznik);
  formData.append('stacje_id', stacje_id);
  formData.append('opis', opis);

  return axios.post(`${API_URL}zdjecia`, formData, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteGallery = (id) => axios.delete(`${API_URL}zdjecia/${id}`, { headers: authHeader() });

const getTankStation = (id) => axios.get(`${API_URL}zbiorniki/${id}`, { headers: authHeader() });

const postDashboard = () => axios.post(`${API_URL}stacje/dashboard2`, { data: '' }, { headers: authHeader() }).catch(handleUnauthorized);

export default {
  getStationAll,
  getStationDetails,
  addStation,
  updateStation,
  deleteStation,
  updateDis,
  getDisAll,
  deleteDis,
  addDistribution,
  addGun,
  addLegalization,
  addVRSLegalization,
  getInvoiceStation,
  addInvoice,
  getProtocolStation,
  addProtocol,
  getGalleryStation,
  addGallery,
  getGunAll,
  addTank,
  addCave,
  getTankTestsAll,
  addTankTest,
  postDashboard,
  deleteGun,
  updateGun,
  deleteInvoice,
  deleteProtocol,
  deleteGallery,
  deleteTank,
  deleteCave,
  updateCave,
};
