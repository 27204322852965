import { IconHome, IconError404 } from '@tabler/icons-react';

// assets
const icons = {
  IconHome,
  IconError404,
};
// constant

const home = {
  id: 'home',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: icons.IconHome,
    },
  ],
};

export default home;
