import { SHOW_GALLERY_STATION, ADD_GALLERY, REMOVE_GALLERY } from '../actions/typ';

const initialState = {
  data: { zdjecia: [] },
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_GALLERY_STATION:
      return {
        data: payload.data,
      };
    case ADD_GALLERY:
      return {
        ...state,
        data: { ...state.data, zdjecia: [payload.data, ...state.data.zdjecia] },
      };
    case REMOVE_GALLERY:
      // eslint-disable-next-line no-case-declarations
      const updated = state.data.zdjecia.filter((zdjecie) => zdjecie.id !== payload.id);
      return {
        ...state,
        data: { ...state.data, zdjecia: updated },
      };
    default:
      return state;
  }
}
