import { SET_MESSAGE, CLEAR_MESSAGE } from '../actions/typ';

const initialState = { show: false };

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE:
      return { message: payload.message, show: true, status: payload.status };

    case CLEAR_MESSAGE:
      return { message: '', show: false };

    default:
      return state;
  }
}
